<template>
  <div class="container">
    <div class="main">
      <PlateTitle title="基本户信息" />
      <a-form
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 8 }"
        :form="form"
      >
        <a-form-item
          label="企业基本户名称"
          has-feedback
        >
          <a-input
            v-decorator="['basicAccountNo', {rules: [{required: true, message: '请输入企业基本户名称'}],}]"
            placeholder="请输入企业基本户名称"
            style="width: 280px"
          />
        </a-form-item>
        <a-form-item
          label="企业基本户账号"
          has-feedback
        >
          <a-input
            v-decorator="['basicAccountName', {rules: [{required: true, message: '请输入企业基本户账号'}],}]"
            placeholder="请输入企业基本户账号"
            style="width: 280px"
          />
        </a-form-item>
        <a-form-item
          label="开户许可证"
          has-feedback
        >
          <span class="jiaoyan">支持jpg、png格式文件，文件不能大于5M</span>
          <a-upload
            action="/api/file/upload"
            list-type="picture-card"
            :file-list="fileList"
            :before-upload="beforeUpload"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img
              alt="example"
              style="width: 100%"
              :src="previewImage"
            >
          </a-modal>
        </a-form-item>
      </a-form>
      <div class="btns">
        <Button
          size="large"
          class="btn cancel"
          @click="back"
        >
          返回
        </Button>
    &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          size="large"
          class="btn submit"
          html-type="submit"
          type="primary"
          @click="submit"
        >
          立即提交
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
import { Button } from 'ant-design-vue';
import PlateTitle from '@/components/PlateTitle';
import { submitEnterprise, getEnterprise } from '@/api/insure.js';
// const querystring = require('querystring');

export default {
  name: 'InsurePage',
  components: {
    PlateTitle,
    Button,
  },
  data() {
    return {
      isTo: false,
      id: '',
      openingAccountLicence: '',
      form: this.$form.createForm(this, { name: 'loginForm' }),
      previewVisible: false,
      previewImage: '',
      fileList: [],
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.reflect();
  },
  methods: {
    reflect() {
      getEnterprise({ insureId: this.id }).then(res => {
        if (res && res.status !== 400) {
          this.form.setFieldsValue({
            basicAccountNo: res.basicAccountNo,
            basicAccountName: res.basicAccountName,
          });
          this.fileList = [{
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: res.openingAccountLicence,
          }];
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    submit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.openingAccountLicence === '') {
            this.$message.warning('请上传开户许可证');
            return false;
          }
          const params = {
            insureId: this.id,
            basicAccountNo: values.basicAccountNo,
            basicAccountName: values.basicAccountName,
            openingAccountLicence: this.openingAccountLicence,
          };
          submitEnterprise(params).then(res => {
            if (res.status === 200) {
              this.$message.success('修改成功');
              this.$router.go(-1);
            }
          });
        }
      });


    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(info) {
      if (info.fileList.length === 0) {
        this.fileList = info.fileList;
        this.openingAccountLicence = '';
        return false;
      }
      if (this.isTo) {
        this.fileList = info.fileList;
        this.openingAccountLicence = info.fileList[0].response;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('只能上传jpg，png格式文件');
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传文件不大于5MB');
      }
      if (isJpgOrPng && isLt2M) {
        this.isTo = true;
      } else {
        this.isTo = false;
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>
<style lang="less" scoped>
.jiaoyan {
  display: block;
  color: #999;
}
.container{
  background-color: #fff;
  min-height: 726px;
}
.main{
  padding: 30px 50px 95px;
}
.pickCompany{
  margin-top:90px;
}
.operate{
  margin-top:80px;
}
.btns {
  text-align: center;
  margin-top: 200px;
}
.btn{
    width: 200px;
    height: 48px;
    &.cancel{
      border-color:#40a9ff;
      color:#40a9ff
    }
    &.submit{
      margin-left:50px;
    }
  }

</style>
